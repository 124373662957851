@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Inter";
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #727272;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dc2626;
}

/* FOOTER */
.footer {
  width: 100%;
  background: #f7f7f7;
  display: block;
}

.inner-footer {
  width: 95%;
  margin: auto;
  padding: 30px 10px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
}

.footer-items {
  width: 25%;
  padding: 10px 20px;
  box-sizing: border-box;
  color: #181818;
}

.footer-items p {
  font-size: 16px;
  text-align: justify;
  line-height: 25px;
  color: #181818;
}

.footer-items h1 {
  color: #181818;
}

.border1 {
  height: 3px;
  width: 40px;
  background: #dc2626;
  color: #dc2626;
  background-color: #dc2626;
  border: 0px;
}

ul {
  color: #181818;
  font-size: 15px;
  letter-spacing: 0.5px;
}

ul a {
  text-decoration: none;
  outline: none;
  color: #181818;
  transition: 0.3s;
}

ul a:hover {
  color: #dc2626;
}

ul li {
  margin: 10px 0;
  height: 25px;
  display: flex;
  align-items: center;
}

li i {
  margin-right: 20px;
}

.social-media {
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.social-media a {
  text-decoration: none;
}

.social-media i {
  height: 25px;
  width: 25px;
  margin: 20px 10px;
  padding: 4px;
  color: #fff;
  transition: 0.5s;
}

.social-media i:hover {
  transform: scale(1.5);
}

.footer-bottom {
  padding: 10px;
  background: #e40000;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

/* for tablet mode view */

@media screen and (max-width: 1275px) {
  .footer-items {
    width: 50%;
  }
}

/* for mobile screen view */

@media screen and (max-width: 660px) {
  .footer-items {
    width: 100%;
  }
}

.seperator {
  width: 100%;
}

.controls-wrapper {
  width: 100%;
  background-color: #e40000 !important;
}

.carousel-wrapper {
  width: 100%;
}

.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px #e40000 !important;
}

.plansScroll::-webkit-scrollbar {
  display: none;
}
.card-ott-icon::-webkit-scrollbar {
  display: none;
}
.stripPlans {
  background: linear-gradient(to right, #3023ae, #c86dd7);
}
.stripPlansWeb {
  background: linear-gradient(to right, #333333, #181818);
}

.getForm {
  backdrop-filter: blur(50px);
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: saturate(180%) blur(50px);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .container {
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
  }
}
/* you can toggle the media scree
n view accordingly by changing the (max-width: px) to your convenience */

/* Thanks to Computer Conversations */
